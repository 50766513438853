export const useImagesThumbnail = () => {
  const config = useRuntimeConfig()
  const imageHost = config.public.IMAGE_CDN_URL
  const defaultDevImage = '6caa5549-482c-486a-5329-be839d7c4800'
  const defaultProdImage = '4a14a844-a00a-4d9f-c0d8-e4e59ab02800'

  const _getDefaultImageId = () => {
    return config.public.PHASE === 'prod' ? defaultProdImage : defaultDevImage
  }

  const imagePathById = (id: string) => {
    return `${imageHost}/${id}`
  }

  const thumbnailImage = (id?: string) => {
    if (!id) {
      id = _getDefaultImageId()
    }
    const option = 'w=282,h=160,fit=cover'
    return `${imageHost}/${id}/${option}`
  }

  const thumbnailImageWithOptions = (id?: string, option?: { w: number }) => {
    if (!id) {
      id = _getDefaultImageId()
    }
    const defaultOption = 'fit=cover'
    const mergedOption = option ? `w=${option.w},${defaultOption}` : defaultOption
    return `${imageHost}/${id}/${mergedOption}`
  }

  return {
    imagePathById,
    thumbnailImage,
    thumbnailImageWithOptions
  }
}
